export default {
  data() {
    return {
      texts: "",
      title: "",
      descripcion: "",
      note: "",
      chkLabel: "",
      api: "",
      returnMain: false,
      dobleConfirm: false,
      chkDelete: false,
      bLoading: false,
      screenWidth: 0,
    };
  },
  beforeMount() {
    this.texts = FILE.deleteTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();
  },
  methods: {
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 45;
      } else {
        this.screenWidth = 100;
      }
    },
    close() {
      this.$store.commit("setDelete", {
        active: false,
        title: "",
        descipcion: "",
        api: "",
      });

      this.$store.commit("setDialogDetailsCustomerWareHouse", {
        active: false,
        arr: [],
      });

      this.$store.commit("setdialogGlobalWarehouse", {
        active: false,
        arr: [],
        apidetail: "",
        apidocument: "",
      });

      this.clearForm();
    },
    clearForm() {
      this.title = "";
      (this.descipcion = ""), (this.api = "");
    },
    deleteItem() {
      this.bLoading = true;

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.delete(`${this.api}`, config)
        .then((response) => {
          this.bLoading = false;
          this.close();
          this.mixSuccess(response.data.message);
          if (this.returnMain) {
            this.$router.go(-1);
          } else {
            this.$emit("emitClose");
            this.$store.commit("refresher", true);
          }
        })
        .catch((error) => {
          this.bLoading = false;
          this.close();
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  watch: {
    dialogDelete() {
      if (this.dialogDelete) {
        this.$store.commit("setMenuSidebar", { active: false });

        this.title = this.$store.state.titleDelete;
        // this.descipcion = this.$store.state.descripcionDelete;
        this.descripcion = this.$store.state.descripcionDelete;
        this.note = this.$store.state.noteDelete;
        this.chkLabel = this.$store.state.chkLabelDelete;
        this.api = this.$store.state.apiDelete;
        this.returnMain = this.$store.state.returnMain;
        this.dobleConfirm = this.$store.state.dobleConfirm;
        !this.dobleConfirm ? (this.chkDelete = true) : (this.chkDelete = false);
        this.texts = FILE.deleteTexts[this.selectLanguage];
      }
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.deleteTexts[this.selectLanguage];
      }
    },
  },
  computed: {
    dialogDelete() {
      return this.$store.state.dialogDelete;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
};
